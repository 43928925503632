import React, { useState, useEffect } from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";
import { isPhoneNumber } from "../../common/utils";
import { generalBankList } from "../../common/constants";


const ModalAddRequisiteProfileNew = ({ active, setActive, BankProfile, isEdit=false, editDitails={} }) => {
  const [id, setId] = useState("");
  const [pan, setPan] = useState("");
  const [totalAmount, setTotalAmount] = useState(10000);
  const [usedTotalAmount, setUsedTotalAmount] = useState(0);
  const [available, setAvailable] = useState(1);
  const [isMain, setIsMain] = useState(0);

  /*
        bank_profile_id,
        pan,
        total_amount, // 10000
        used_total_amount, // 0
        available, // 1 or 0
        is_main
  */

 

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/requisiteprofiles/create",
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!active) {
      setId(0)
      setPan("")
      setTotalAmount(5000000);
      setUsedTotalAmount(0)
      setAvailable(1);
      setIsMain(0);
    } else {
      if(isEdit){
        setId(editDitails.id);
        setPan(editDitails.pan);
        setTotalAmount(editDitails.total_amount);
        setUsedTotalAmount(editDitails.used_total_amount);
        setAvailable(editDitails.available);
        setIsMain(editDitails.is_main);
      
      }
    }
  }, [active, isEdit, editDitails, setId, setPan, setTotalAmount, setUsedTotalAmount, setAvailable, setIsMain]);

  const submitModal = async () => {
    await executePost({
      data: {
        bank_profile_id: BankProfile,
        pan,
        total_amount: totalAmount,
        used_total_amount: usedTotalAmount,
        available,
        id,
        is_main: isMain
      },
    })
      .then((data) => {
        setActive(false);
        if(!isEdit)
          toast.success("Реквизит успешно добавлен!");
        else
          toast.success("Реквизит успешно изменен!");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal6"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Добавление реквизита на банк #{BankProfile}</div>
          <div
            className="dialog__head-close"
            id="modal6Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <div className="custom-input custom-input_block">
            <label htmlFor="deviceprofile-phone">Номер карты</label>
            <input
              value={pan}
              type="text"
              id="deviceprofile-phone"
              autoComplete="off"
              placeholder="228228"
              onChange={(e) => setPan(e.target.value)}
            />
            
          </div>

         
          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Максимум на карту</label>
            <input
              value={totalAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Использовано на карте</label>
            <input
              value={usedTotalAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setUsedTotalAmount(e.target.value)}
            />
          </div>


          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="requisiteprofile-ismain"
                checked={isMain === 1 ? true : false}
                onChange={() => setIsMain(isMain === 1 ? 0 : 1)}
              />
              <label htmlFor="requisiteprofile-ismain">Основной реквизит? (если номер это сбп)</label>
            </div>
          </div>

          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="requisiteprofile-available"
                checked={available === 1 ? true : false}
                onChange={() => setAvailable(available === 1 ? 0 : 1)}
              />
              <label htmlFor="requisiteprofile-available">Доступность</label>
            </div>
          </div>


          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              {isEdit?'Сохранить':'Добавить'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddRequisiteProfileNew;
